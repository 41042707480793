import React, { useState } from "react";
import styles from "./Styles/Contact.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeOpen } from "@fortawesome/free-solid-svg-icons";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

const Contact = () => {
  const [subject, setSubject] = useState("");
  const [poruka, setPoruka] = useState("");
  let ref = "mailto:montestage@gmail.com?subject="
    .concat("", subject)
    .concat("&body=")
    .concat(poruka);

  return (
    <div className={styles.contact}>
      <div className={styles.bigContainer}>
        <div className={styles.container}>
          <p>
            <FontAwesomeIcon icon={faEnvelopeOpen} style={{ color: "black" }} />{" "}
            montestage@gmail.com
          </p>
          <p>
            <FontAwesomeIcon icon={faPhone} style={{ color: "black" }} />{" "}
            +38269647354
          </p>
          <p>
            <a href="https://www.instagram.com/montestage/" target="blank">
              <span>
                <svg
                  width="30"
                  height="30"
                  className={styles.svg}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 800 800"
                >
                  <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                </svg>
              </span>
            </a>
          </p>
        </div>
        <div className={styles.container}>
          <label htmlFor="subject">Naslov</label>
          <input
            className={styles.black}
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            type="text"
            maxLength="20"
            id="subject"
          ></input>

          <label htmlFor="poruka">Poruka</label>
          <textarea
            rows="8"
            className={styles.tArea}
            value={poruka}
            onChange={(e) => setPoruka(e.target.value)}
            id="poruka"
          ></textarea>

          <a href={ref} className={styles.send}>
            <FontAwesomeIcon icon={faPaperPlane} />
            Pošaljite nam email!
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
